import axios from "axios";
import authHeader from "@/store/auth-header";
const user = JSON.parse(localStorage.getItem('user'));

export const mediaModule = {
    namespaced: true, state: {
        mediaCache: []
    },

    actions: {
        uploadImageToServer({commit, dispatch}, image) {
            return new Promise((resolve, reject) => {
                const form = new FormData();
                form.append('path', "user");
                form.append('file', image, "image-" + Date.now() + ".jpg");

                let request = axios.post(process.env.VUE_APP_API_URL + "/api/v1/media/", form, {
                    headers: {
                        ...authHeader(),
                        'Content-Type': 'multipart/form-data',
                        'content-type': 'multipart/form-data; boundary=---011000010111000001101001'
                    }
                });
                request.then(result => {
                    commit("addNewMedia", result.data);
                    dispatch("getImagesFromCurrentUser");
                    return resolve(result);
                });

                request.catch(error => {
                    return reject(error);
                });
            });
        },

        getImagesFromCurrentUser({commit}) {
            // https://api.evaty.net/api/v1/user/a86a4c08-966e-4fca-a1e1-8623da4570e5/media/?page=0
            return new Promise((resolve, reject) => {
                let request = axios.get(process.env.VUE_APP_API_URL + "/api/v1/user/" + user.id + '/media/?page=0' ,{headers: authHeader()});
                request.then(result => {
                    result.data.content.forEach(media => {
                        commit("addNewMedia", media);
                        resolve(result)
                    })
                });

                request.catch(error => {
                    reject(error);
                });
            });
        },

        deleteImage({commit}, mediaId) {
            return new Promise((resolve, reject) => {
                let request = axios.delete(process.env.VUE_APP_API_URL + "/api/v1/media/" + mediaId + '/', {headers: authHeader()});
                request.then(result => {
                    commit("removeMedia", mediaId);
                    resolve(result)
                });

                request.catch(error => {
                    reject(error);
                });
            });
        }
    },

    mutations: {
        addNewMedia(state, media) {
            console.log(state.mediaCache.filter(m => m.id === media.id).length);
            if(state.mediaCache.filter(m => m.id === media.id).length > 0) return
            state.mediaCache.push(media)
        },
        removeMedia(state, mediaId) {
            var filtered = state.mediaCache.filter(function(el) { return el.id != mediaId; });
            state.mediaCache = filtered;
        }
    },

    getters: {
        getAllMediasFromUser(state) {
            return state.mediaCache;
        }
    }
}
