import axios from "axios";
import authHeader from "@/store/auth-header";

export const statsModule = {
    namespaced: true,
    state: {
        stats: {}
    },

    actions: {
      fetchStats({commit}) {
          return new Promise((resolve, reject) => {
              const request = axios.get(process.env.VUE_APP_API_URL + "/api/v1/stats/", {headers: authHeader()});
              request.then(result => {
                  commit("setStats", result.data);
                 resolve();
              });
              request.catch(error => {
                  reject(error);
              });
          })
      }
    },

    mutations: {
        setStats(state, stats) {
            state.stats = stats;
        }
    },

    getters: {
      getStats(state) {
          return state.stats;
      }
    }
}