import axios from "axios";
import authHeader from "@/store/auth-header";
const API_URL = process.env.VUE_APP_API_URL + '/api/v1/user/';

export const userModule = {
    namespaced: true,
    state: {
        user: {}
    },
    actions: {
        fetchUser({commit}, id) {
            return new Promise((resolve, reject) => {
                let request = axios.get(API_URL + id + "/", {headers: authHeader()});

                request.then(result => {
                    commit("setUser", result.data)
                    return resolve(result.data);
                });

                request.catch(error => {
                    return reject(error);
                });
            });
        },
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        }
    },
    getters: {
        getUser: (state) => {
            return state.user;
        }
    }
}
