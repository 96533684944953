import axios from "axios";
import authHeader from "@/store/auth-header";

export const editModule = {
    namespaced: true,
    state: {
        event: null,
        user: null
    },

    actions: {
        getEvent({commit}, eventId) {
            return new Promise((resolve, reject) => {
                const request = axios.get(process.env.VUE_APP_API_URL + "/api/v2/event/" + eventId + "/", {headers: authHeader()});
                request.then(result => {
                    let event = result.data.body
                    commit("setEvent", event);
                    resolve(event);
                })
                request.catch(error => {
                    reject(error);
                });
            })
        },

        getUser({commit}, userId) {
            return new Promise((resolve, reject) => {
                const request = axios.get(process.env.VUE_APP_API_URL + "/api/v1/user/" + userId + "/", {headers: authHeader()});
                request.then(result => {
                    let user = result.data.body;
                    commit("setUser", user);
                    resolve(user);
                })
                request.catch(error => {
                    reject(error);
                })
            })
        },
        saveEvent({state}) {
            return new Promise((resolve, reject) => {
                const request = axios.put(process.env.VUE_APP_API_URL + "/api/v2/event/" + state.event.id + "/",
                    state.event, {headers: authHeader()});
                request.then(() => {
                    resolve();
                });
                request.catch(error => {
                    reject(error);
                })
            });
        },
        saveUser({state}) {
            return new Promise((resolve, reject) => {
                const request = axios.put(process.env.VUE_APP_API_URL + "/api/v2/user/" + state.user.id + "/",
                    state.user, {headers: authHeader()});
                request.then(() => {
                    resolve();
                });
                request.catch(error => {
                    reject(error);
                })
            });
        },
        addEventDate({commit, state}, date) {
            return new Promise((resolve) => {
                let dates = state.event.dates;
                if (dates == null) {
                    dates = new Array(date);
                } else {
                    dates.push(date);
                }

                commit("setEventDate", dates);
                resolve();
            })
        },
        removeEventDate({commit, state}, index) {
            return new Promise((resolve, reject) => {
                let dates = state.event.dates;
                console.log(index);
                console.log(dates.length);
                if (dates.length >= index) {
                    dates.splice(index, 1)
                    commit("setEventDate", dates);
                    resolve();
                } else {
                    // dates.push(date);
                    reject();
                }
            })
        },
    },
    mutations: {
        setEventDate(state, dates) {
            console.log(state.event);
            state.event.dates = dates;
        },
        setEvent(state, event) {
            state.event = event;
        },
        setUser(state, user) {
            state.user = user;
        }
    },
    getters: {
        getCurrentEvent(state) {
            return state.event;
        },
        getCurrentUser(state) {
            return state.user;
        }
    }
}