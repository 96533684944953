import axios from "axios";
import authHeader from "@/store/auth-header";
import router from "@/router";

const user = JSON.parse(localStorage.getItem('user'));

export const eventModule = {
    namespaced: true,
    state: {
        eventCache: []
    },
    actions: {
        // eslint-disable-next-line no-empty-pattern
        deleteEvent({}, eventId) {
            return new Promise((resolve, reject) => {
                let request = axios.delete(process.env.VUE_APP_API_URL + "/api/v2/event/" + eventId + "/", {headers: authHeader()});
                request.then(result => {
                    resolve(result)
                });
                request.catch(error => {
                    reject(error);
                });
            });
        },
        getEventsFromUser({commit}) {
            return new Promise((resolve, reject) => {
                let request = axios.get(process.env.VUE_APP_API_URL + "/api/v1/user/" + user.id + '/events/?page=0', {headers: authHeader()});
                request.then(result => {
                    result.data.content.forEach(media => {
                        commit("addNewEvent", media);
                        resolve(result)
                    })
                });

                request.catch(error => {
                    reject(error);
                });
            });
        },
        // eslint-disable-next-line no-empty-pattern
        async createNewEvent({}, eventObj) {
            const locMeta = await getLocationFromLatLng(eventObj.lat, eventObj.lng);
            let locationMeta = {
                city: locMeta.address.city,
                country: locMeta.address.countryName,
                label: locMeta.address.label
            }
            let location = {
                y: eventObj.lat,
                x: eventObj.lng
            }
            let event = {
                name: eventObj.name,
                location: location,
                locationMeta: locationMeta,
                hostIds: eventObj.hostIds
            }

            const req = axios.post(process.env.VUE_APP_API_URL + "/api/v2/event/", event, {headers: authHeader()});
            req.then(answ => {
               router.push("/event/" + answ.data.body.event.id)
            });
        }
    },
    mutations: {
        addNewEvent(state, event) {
            if (state.eventCache.filter(e => e.id === event.id).length > 0) return
            state.eventCache.push(event)
        },
    },
    getters: {
        getAllEventsFromUser(state) {
            return state.eventCache;
        }
    }
}

async function getLocationFromLatLng(lat, lng) {
    return new Promise((resolve, reject) => {
        const request = axios.get("https://revgeocode.search.hereapi.com/v1/revgeocode" + queryStringFormat({
            apiKey: "qc6XXJvTgZjC9_4PIyJeZKPJELILiJxkTVfm8Dkep3k",
            at: lat + ',' + lng,
            lang: "de-DE"
        }));
        request.then(result => {
            // console.log(result);
            resolve(result.data.items[0]);
        });
        request.catch(() => {
            reject(false);
        })
    });
}

function queryStringFormat(query) {
    return `?${Object.entries(query)
        .map(([k, v]) => k + '=' + encodeURIComponent(v))
        .join('&')}`
}
