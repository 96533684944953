import { createApp } from 'vue'
import router from "@/router";
import store from "@/store";
import i18n from "@/i18n";

import { vue3Debounce } from 'vue-debounce'

import VueSweetalert2 from 'vue-sweetalert2';
import VueClickAway from "vue3-click-away";
import 'sweetalert2/dist/sweetalert2.min.css';

//Import Bootstrap
import 'bootstrap-icons/font/bootstrap-icons.scss'
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap';

//Import EvatyStyles
// import './theme/evaty-base.scss'
// import './theme/evaty-variables.css'
// import './theme/evaty-fonts.css'
// import './theme/evaty-main.css'

import './theme/evaty-design-system/base.scss'

//Import DEPENDENCY
import './theme/fontawesome-fonts.css'
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import App from './App.vue'

createApp(App)
    .use(router)
    .use(store)
    .use(VueSweetalert2)
    .use(VueClickAway)
    .use(i18n)
    .directive('debounce', vue3Debounce({ lock: true }))
    .component('Datepicker', Datepicker)
    .mount('#app')
