import axios from "axios";
import router from "@/router";

const API_URL = process.env.VUE_APP_API_URL + '/api/v1/auth/';
const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
    ? { status: { loggedIn: true }, user }
    : { status: { loggedIn: false }, user: null };

export const authModule = {
    namespaced: true,
    state: initialState,
    actions: {
        login({commit}, user) {
            return new Promise((resolve, reject) => {
                let request = axios.post(API_URL + 'signin', {
                    username: user.username,
                    password: user.password
                });

                request.then(response => {
                    if (response.data.accessToken) {
                        console.log(response);
                        localStorage.setItem('user', JSON.stringify(response.data));
                        commit('loginSuccess', response.data);
                    }
                    return resolve(response.data);
                });

                request.catch(error => {
                    commit('loginFailure');
                    return reject(error);
                });
            });
        },

        register({ commit }, user) {
            return new Promise((resolve, reject) => {
                let request = axios.post(API_URL + 'signup', {
                    username: user.username,
                    email: user.email,
                    password: user.password
                });

                request.then(response => {
                    commit('registerSuccess');
                    return resolve(response.data);
                });

                request.catch(error => {
                    commit('registerFailure');
                    return reject(error);
                });
            })
        },

        logout() {
            localStorage.removeItem('user');
            router.replace('/login');
        },
    },
    mutations: {
        loginSuccess(state, user) {console.log(user);
            state.status.loggedIn = true;
            state.user = user;
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        registerSuccess(state) {
            state.status.loggedIn = false;
        },
        registerFailure(state) {
            state.status.loggedIn = false;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.user = null;
        }
    },
    getters: {
        getLoginState: (state) => state.status?.loggedIn,
        getUser: (state) => state.user,
        getUsername: (state) => state.user.username,
        getUserId: (state) => state.user.id,
        // checkIfAdmin: () => false,
        checkIfAdmin: (state) => {
            return state.user.roles.includes("ROLE_MODERATOR") || state.user.roles.includes("ROLE_ADMIN")
        },
        checkIfOfficial: (state) => {
            return state.user.roles.includes("ROLE_OFFICIAL")
        },
        checkIfHasAccess: (state) => {
            return state.user.roles.includes("ROLE_OFFICIAL") || state.user.roles.includes("ROLE_MODERATOR") || state.user.roles.includes("ROLE_ADMIN")
        }
    }
}
