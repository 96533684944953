<template>
  <div class="app">
    <router-view></router-view>
  </div>
<!--  <div class="legal">-->
<!--    <a href="https://evaty.net/imprint">Impressum</a>-->
<!--    <a href="https://evaty.net/privacy">Datenschutz</a>-->
<!--  </div>-->
</template>

<script>

export default {
  name: 'App',
}
</script>
<style lang="scss">
.app {
  height: 100vh;
  width: 100vw;
}
</style>
