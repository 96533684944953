import {createStore} from 'vuex'

import {authModule} from "@/store/modules/auth.module";
import {mediaModule} from "@/store/modules/media.module";
import {eventModule} from "@/store/modules/event.module";
import {userModule} from "@/store/modules/user.module";
import {editModule} from "@/store/modules/edit.module"
import {statsModule} from "@/store/modules/stats.module";

export default createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        authModule,
        mediaModule,
        eventModule,
        userModule,
        editModule,
        statsModule
    }
})
